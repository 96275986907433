import React from "react";

import { AuthenticationContext } from "../providers/Authentication";

export const useAuthentication = (explode = true) => {
	const ctx = React.useContext(AuthenticationContext);
	if (!ctx && explode) {
		throw new Error("Error, context not wrapped!");
	}
	return ctx;
};