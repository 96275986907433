import { Button, buttonVariants } from '../../../components/ui/button';
import { Navbar } from '../../../components/NavBar';
import { FC } from 'react';
import { useAuthentication } from '../../../hooks/useAuthentication';
import Background from '../../../assets/fundo.png';

interface ILandingPage {

}

export const LandingPage: FC<ILandingPage> = () => {
  const { onSignInWithGoogle } = useAuthentication()

  return (
    <div className="relative flex flex-col min-h-screen">
      <img src={Background} className="absolute top-0 left-0 h-screen w-screen" />
      <Navbar />
      <section className="container !p-4 grid lg:grid-cols-2 place-items-center py-20 md:py-4 gap-10">
        <div className="z-10 text-center lg:text-start space-y-6">
          <main className="text-5xl md:text-6xl font-bold">
            <h1 className="inline">
              <span className="inline bg-primary text-transparent bg-clip-text">
                Gerencie
              </span>{" "}
            </h1>{" "}
            Seus{" "}
            <h2 className="inline">
              <span className="inline bg-primary text-transparent bg-clip-text">
                Certificados
              </span>{" "}
              com Facilidade
            </h2>
          </main>

          <p className="text-xl text-muted-foreground md:w-10/12 mx-auto lg:mx-0">
            Tenha em suas mãos todos os certificados recebidos pela MK Soluções, com acesso fácil e controle total.
          </p>

          <div className="space-y-4 md:space-y-0 md:space-x-4">
            <Button className="w-full md:w-1/3" onClick={() => onSignInWithGoogle()}>
              Entrar
            </Button>

            <a
              rel="noreferrer noopener"
              href="https://www.mksolutions.com.br/categoria/geral/"
              target="_self"
              className={`w-full md:w-1/3 ${buttonVariants({
                variant: "outline",
                className: "hover:!text-[#fff] hover:bg-primary"
              })}`}
            >
              Mais Informações
            </a>
          </div>
        </div>

        <div className="hidden lg:flex flex-row flex-wrap gap-8 relative w-[700px] h-[500px]">
          <div className="absolute w-[340px] left-0 top-12 drop-shadow-xl shadow-black/10 dark:shadow-white/10">
            <img src="https://www.mksolutions.com.br/wp-content/uploads/2024/07/planetas-1-1024x591.png" className='max-w-[800px]' alt="" />
          </div>
        </div>
        {/* Shadow effect */}
        <div className="shadow"></div>
      </section>

    </div>
  )
}