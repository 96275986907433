import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "../ui/navigation-menu";



export const Navbar = () => {
  return (
    <header className="sticky top-0 z-40 w-full pt-3  dark:bg-background">
      <NavigationMenu className="mx-auto">
        <NavigationMenuList className="container h-14 px-4 w-screen flex justify-between ">
          <NavigationMenuItem>
            <img src="https://www.mksolutions.com.br/wp-content/uploads/2022/11/mk-positive.svg" alt="MK solutions" className="h-10" />
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </header>
  );
};