import { toast } from "sonner";
import { v4 as uuidv4 } from 'uuid';
import { FC, useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { ClockArrowDown, LoaderCircle } from "lucide-react";

import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

import { dbFireStore } from "src/config/firebase";
import { handleFirebaseRequestError } from "src/utils/handlerErrors";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

interface IConfig {
  name: string;
  limit: number;
}



const DEFAULT_VALUES: IConfig = {
  name: "",
  limit: 0,
};

export const CreateDraw: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<IConfig>(DEFAULT_VALUES);

  function copyToClipboard(ID: string) {
    const currentUrl = `${window.location.origin}/draw/${ID}`;
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        toast.info('Link do sorteio copiado para a área de transferência!');
      })
      .catch((err) => {
        toast.error('Erro ao copiar o link: ', err);
      });
  }

  const onChangeValue = ({ target }: React.ChangeEvent<HTMLInputElement>, field: "name" | "limit") => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [field]: field === "name" ? target.value : target.value,
    }));
  };

  const handlerOpening = (val: boolean) => {
    setIsOpen(val);
    if (!val) {
      setConfig(DEFAULT_VALUES);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (config.name.length &&
      config.limit > 1) {
      setIsLoading(true)
      try {
        const id = uuidv4()
        await addDoc(collection(dbFireStore, "draws"), {
          ...config,
          id: id,
          winner: null,
          participants: [],
          limit: Number(config.limit),
        });
        setIsLoading(false)
        handlerOpening(false)
        toast.success("Sorteio criado com sucesso.", { duration: 150 })
        copyToClipboard(id)
      }
      catch (error) {
        setIsLoading(false)
        console.error(error)
        handleFirebaseRequestError(error as any);
      }

    } else {
      toast.warning("Campos Titulo e Limite são obrigatórios.")
    }
  }

  return (
    <Dialog onOpenChange={handlerOpening} open={isOpen}>
      <DialogTrigger asChild>
        <Button className='gap-2'>
          <ClockArrowDown size={20} />
          Criar sorteio
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Criar sorteio</DialogTitle>
          <DialogDescription>
            Crie um sorteio para um treinamento.
          </DialogDescription>
        </DialogHeader>
        <form id="createDrawForm" onSubmit={onSubmit} className="max-h-[50vh] overflow-y-auto">
          <div className="flex flex-col gap-4 py-4 px-1">
            <div className="flex flex-col gap-2">
              <Label>
                Título do sorteio*
              </Label>
              <Input
                className="mt-2"
                onChange={(e) => onChangeValue(e, 'name')}
                placeholder='Preencha o título do sorteio'
                value={config.name} />
            </div>
            <div className="flex flex-col gap-2">
              <Label>
                Limite de participantes*
              </Label>
              <Input
                min={2}
                type="number"
                className="mt-2"
                value={config.limit}
                onChange={(e) => onChangeValue(e, 'limit')}
                placeholder='Preencha a quantidade máxima de participantes' />
            </div>
          </div>
        </form>
        <DialogFooter>
          <Button form="createDrawForm" disabled={isLoading}>
            {
              isLoading && <LoaderCircle className='animate-spin mr-2' />
            }
            Criar sorteio
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
