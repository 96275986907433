import { useEffect, useState } from "react";

import { UserSession } from "src/components/UserSession";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { UpdateInformations } from "../../../components/UpdateInformations";
import { AdminSession } from "src/components/AdminSession";

interface DashboardProps {

}


export function Dashboard({ }: DashboardProps) {
  const { userInfo } = useAuthentication();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (userInfo !== null) {
      setUser(userInfo);
      setLoading(false);
    }
  }, [userInfo]);

  if (loading) {
    return <div className="min-h-[calc(100vh-64px)] flex items-center justify-center">Carregando...</div>;
  }

  if (user === undefined) {
    return <UpdateInformations />;
  }

  if (user?.isAdmin) {
    return <AdminSession/>;
  }

  return <UserSession />;
}