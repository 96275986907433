import { RouteObject } from "react-router-dom";

import { Dashboard } from "./Dashboard";
import { ProtectedPage } from "../../components/ProtectedPage";


export const ROUTES_PAGES: RouteObject[] = [
	{
		path: "",
		element: <ProtectedPage element={Dashboard} validatePage={true} />,
	},
];