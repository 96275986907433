import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '../Tooltip';
import { FC, ReactNode } from 'react';

interface ITooltipMessage {
  message: string;
  children: ReactNode;
}

export const TooltipMessage: FC<ITooltipMessage> = ({
  message,
  children,
}) => {
  
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent>
          {message}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}