import React from "react";
import { Outlet } from "react-router-dom";

import { NavBarLogged } from "../../../components/NavBarLogged";

const Layout: React.FC = () => {

  return (
    <div className="min-h-screen flex flex-col">
      <NavBarLogged />
      <Outlet />
    </div>
  );
};

export default Layout;