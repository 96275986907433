import { BookCheck } from 'lucide-react';
import { FC, useEffect, useState } from 'react';

import { collection, doc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { toast } from 'sonner';
import { dbFireStore } from 'src/config/firebase';
import { handleFirebaseRequestError } from 'src/utils/handlerErrors';
import { ActionsDropdown } from "../ActionsDropdown";
import { CreateDraw } from '../CreateDraw';
import { CreateReview } from '../CreateReview';
import * as TableRadix from "../Table";
import { TooltipMessage } from '../TooltipMessage';
import { Badge } from '../ui/badge';
import * as Card from "../ui/card";
import { DropdownMenuItem } from "../ui/dropdown-menu";
import clsx from 'clsx';

function truncateString(input: string, limit: number) {
  if (input.length > limit) {
    return input.slice(0, limit) + '...';
  }
  return input;
}

interface IReview {
  id: string;
  name: string;
  answers: number;
  isValid: boolean;
  description_for_certificate: string;
}

interface IDraw {
  id: string;
  name: string;
  limit: number;
  winner: string | null;
  participants: string[];
}


export const AdminSession: FC = () => {
  const [reviews, setReviews] = useState<IReview[]>([])
  const [drawsAvailable, setDrawsAvailable] = useState<IDraw[]>([])


  const onFinishReview = async (review: IReview) => {
    const q = query(collection(dbFireStore, 'reviews'), where('id', '==', review.id));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs[0].id

    if (typeof data === "string") {
      try {
        await setDoc(doc(dbFireStore, "reviews", data), {
          ...review,
          isValid: false
        });
      } catch (error) {
        handleFirebaseRequestError(error as any);
      }
    }
  }



  const onMakeWinner = async (draw: IDraw) => {
    const winner = draw.participants[Math.floor(Math.random() * draw.participants.length)];

    const q = query(collection(dbFireStore, 'draws'), where('id', '==', draw.id));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs[0].id;

    const qUser = query(collection(dbFireStore, 'usersInfo'));
    const querySnapshotUser = await getDocs(qUser);
    const users = querySnapshotUser.docs.map((e) => e.data())
    const dataUser = users.find((e) => e.email === winner)

    if (dataUser && winner) {
      try {
        await setDoc(doc(dbFireStore, "draws", data), {
          ...draw,
          winner: winner,
        });
        await setDoc(doc(dbFireStore, "userEvents", "draw"), {
          drawID: data,
          winner: dataUser.email,
          winnerMessage: `Parabéns, você ganhou o sorteio ${draw.name}`,
        });
        toast.success(`Ganhador é ${dataUser.name}`);
      } catch (error) {
        console.log(error)
        handleFirebaseRequestError(error as any);
      }
    }
  }

  useEffect(() => {
    const unsub = onSnapshot(
      collection(dbFireStore, "reviews"),
      (collection) => {
        !collection.empty && setReviews(() => collection.docs.map((store) => store.data() as IReview))
      },
    );
    return () => unsub();
  }, [])

  const teste = async (reviewID: string) => {
    const usersSnapshot = await getDocs(collection(dbFireStore, 'usersInfo'));
    const npsDistribution = {
      1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0
    };

    usersSnapshot.docs.forEach((userDoc) => {
      const userData = userDoc.data();

      userData.reviews.forEach((review: any) => {
        const npsResponses = review.values.filter(
          (value: any) => value.position === 0
        );

        npsResponses.forEach((response: any) => {
          const score = parseInt(response.value, 10);

          if ((npsDistribution as any)[score] !== undefined) {
            (npsDistribution as any)[score]++;
          }
        });
      });
    });
    console.log(npsDistribution)
  }

  useEffect(() => {
    const q = query(collection(dbFireStore, 'draws'));
    const unsub = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        setDrawsAvailable(snapshot.docs.map((doc) => doc.data() as IDraw));
      } else {
        setDrawsAvailable([]);
      }
    });
    return () => unsub();
  }, [reviews]);

  return <div className="h-full min-h-[calc(100vh-64px)] flex gap-4 p-4">
    <Card.Card className="w-full">
      <Card.CardHeader>
        <Card.CardTitle>Feedbacks já criados</Card.CardTitle>
        <Card.CardDescription>Confira a listagem de feedbacks de conteúdos já criado.</Card.CardDescription>
      </Card.CardHeader>
      <Card.CardContent>
        <TableRadix.Table>
          <TableRadix.TableHeader>
            <TableRadix.TableRow>
              <TableRadix.TableHead>Nome</TableRadix.TableHead>
              <TableRadix.TableHead>Numeros de respostas</TableRadix.TableHead>
              <TableRadix.TableHead>Descrição para certificado</TableRadix.TableHead>
              <TableRadix.TableHead>Válido</TableRadix.TableHead>
              <TableRadix.TableHead className="text-right">Ações</TableRadix.TableHead>
            </TableRadix.TableRow>
          </TableRadix.TableHeader>
          <TableRadix.TableBody>
            {reviews.map((review) => (
              <TableRadix.TableRow key={review.id}>
                <TableRadix.TableCell>
                  <TooltipMessage message={review.name}>
                    {truncateString(review.name, 15)}
                  </TooltipMessage>
                </TableRadix.TableCell>
                <TableRadix.TableCell>
                  {review.answers}
                </TableRadix.TableCell>
                <TableRadix.TableCell>
                  <TooltipMessage message={review.description_for_certificate}>
                    {truncateString(review.description_for_certificate, 24)}
                  </TooltipMessage>
                </TableRadix.TableCell>
                <TableRadix.TableCell>
                  <Badge className={clsx({
                    "bg-green-400": review.isValid,
                    "bg-red-400": !review.isValid
                  })}>
                    <div className='w-2 h-2 mr-2 rounded-full bg-white'></div>
                    {
                      review.isValid ?
                        "Ativo" :
                        "Finalizado"
                    }
                  </Badge>
                </TableRadix.TableCell>
                <TableRadix.TableCell className="flex justify-end">
                  <ActionsDropdown>
                    <DropdownMenuItem className="gap-2 !text-black cursor-pointer" onClick={() => onFinishReview(review)}>
                      <BookCheck size={20} />
                      Finalizar feedback
                    </DropdownMenuItem>
                    {
                      !review.isValid ?
                        <DropdownMenuItem className="gap-2 !text-black cursor-pointer" onClick={() => teste(review.id)}>
                          <BookCheck size={20} />
                          Detalhes do sorteio
                        </DropdownMenuItem>
                        : <></>
                    }
                  </ActionsDropdown>
                </TableRadix.TableCell>
              </TableRadix.TableRow>
            ))}
          </TableRadix.TableBody>
        </TableRadix.Table>
      </Card.CardContent>
    </Card.Card>
    <div className='flex flex-col min-w-96'>
      <div className='flex flex-col gap-2 mb-4'>
        <CreateReview />
        <CreateDraw />
      </div>
      <Card.Card className='h-full'>
        <Card.CardHeader>
          <Card.CardTitle>Lista de sorteio</Card.CardTitle>
          <Card.CardDescription>Listagem de sorteio ativos no momento.</Card.CardDescription>
        </Card.CardHeader>
        <Card.CardContent>
          <TableRadix.Table>
            <TableRadix.TableHeader>
              <TableRadix.TableRow>
                <TableRadix.TableHead>Nome</TableRadix.TableHead>
                <TableRadix.TableHead>Ganhador</TableRadix.TableHead>
                <TableRadix.TableHead className="text-right">Ações</TableRadix.TableHead>
              </TableRadix.TableRow>
            </TableRadix.TableHeader>
            <TableRadix.TableBody>
              {drawsAvailable.map((draw) => (
                <TableRadix.TableRow key={draw.id}>
                  <TableRadix.TableCell>
                    <TooltipMessage message={draw.name}>
                      {truncateString(draw.name, 24)}
                    </TooltipMessage>
                  </TableRadix.TableCell>
                  <TableRadix.TableCell>
                    {
                      draw.winner ?
                        <TooltipMessage message={draw.winner}>
                          {truncateString(draw.winner, 18)}
                        </TooltipMessage>
                        : '---'
                    }
                  </TableRadix.TableCell>
                  <TableRadix.TableCell className="flex justify-end">
                    <ActionsDropdown disabled={draw.winner !== null}>
                      <DropdownMenuItem className="gap-2 !text-black cursor-pointer" onClick={() => onMakeWinner(draw)}>
                        <BookCheck size={20} />
                        Sortear ganhador
                      </DropdownMenuItem>
                    </ActionsDropdown>
                  </TableRadix.TableCell>
                </TableRadix.TableRow>
              ))}
            </TableRadix.TableBody>
          </TableRadix.Table>
        </Card.CardContent>
      </Card.Card>
    </div>
  </div>
}