import { FC, ReactNode } from 'react';
import { Ellipsis } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from '../ui/dropdown-menu';

interface IActionsDropdown {
  children: ReactNode;
  disabled?: boolean;
}

export const ActionsDropdown: FC<IActionsDropdown> = ({ children, disabled = false }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={disabled} className='hover:bg-gray-200 rounded-md transition-all p-0.5'>
        <Ellipsis />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}