import { toast } from "sonner";
import { v4 as uuidv4 } from 'uuid';
import { FC, useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { LoaderCircle, Plus, TicketCheck, Trash } from "lucide-react";

import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

import { dbFireStore } from "src/config/firebase";
import { handleFirebaseRequestError } from "src/utils/handlerErrors";
import { Card } from "../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

interface IForms {
  label: string;
  values: string[];
  type: 'text' | 'multiple' | 'one_to_ten';
}

interface IConfig {
  name: string;
  description_for_certificate: string;
}

const DEFAULT_VALUES: IConfig = {
  name: "",
  description_for_certificate: "",
};

interface ValidationResult {
  isValid: boolean;
  message: string | null;
}

function validateItems(items: IForms[]): ValidationResult {
  for (const item of items) {
    if (item.label.trim().length <= 1) {
      return {
        isValid: false,
        message: `Label inválida para o tipo ${item.type}. A label deve ter mais de um caractere.`
      };
    }

    if (item.type === 'multiple') {
      if (item.values.length < 2) {
        return {
          isValid: false,
          message: `Tipo 'multiple' deve ter pelo menos duas opções.`
        };
      }
      if (item.values.filter((e) => e !== "").length < 2) {
        return {
          isValid: false,
          message: `Campos de multiplas escolhas são devem ser preenchidos.`
        };
      }
    }
  }

  return {
    isValid: true,
    message: null
  };
}


export const CreateReview: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<IConfig>(DEFAULT_VALUES);
  const [forms, setForms] = useState<IForms[]>([]);

  function copyToClipboard(ID: string) {
    const currentUrl = `${window.location.origin}/review/${ID}`;
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        toast.info('Link do feedback copiado para a área de transferência!');
      })
      .catch((err) => {
        toast.error('Erro ao copiar o link: ', err);
      });
  }

  const onChangeValue = ({ target }: React.ChangeEvent<HTMLInputElement>, field: "name" | "description_for_certificate") => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [field]: target.value,
    }));
  };

  const onCreateField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    const temp = [...forms];
    temp.push({
      values: [''],
      type: 'text',
      label: ``,
    })
    setForms(temp);
  };

  const handlerOpening = (val: boolean) => {
    setIsOpen(val);
    if (!val) {
      setForms([]);
      setConfig(DEFAULT_VALUES);
    }
  };

  const handlerLabelField = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    const temp = forms;

    temp[index].label = value

    setForms(temp);
  };

  const handlerOptionField = (value: 'text' | 'multiple', index: number) => {
    setForms((prevForms) => prevForms.map((form, i) =>
      i === index ? { ...form, type: value } : form
    ));
  };

  const addOption = (index: number) => {
    setForms((prevForms) => prevForms.map((form, i) =>
      i === index ? { ...form, values: [...form.values, ''] } : form
    ));
  };

  const handleOptionChange = (index: number, optionIndex: number, value: string) => {
    setForms((prevForms) => prevForms.map((form, i) =>
      i === index ? {
        ...form,
        values: form.values.map((opt, j) => j === optionIndex ? value : opt)
      } : form
    ));
  };

  const handleDeleteField = (index: number) => {
    setForms((prevForms) => prevForms.filter((_, i) => i !== index));
  };

  const handleDeleteOption = (fieldIndex: number, optionIndex: number) => {
    setForms((prevForms) => prevForms.map((form, i) =>
      i === fieldIndex ? {
        ...form,
        values: form.values.filter((_, j) => j !== optionIndex)
      } : form
    ));
  };


  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const { isValid, message } = validateItems(forms);

    if (isValid) {
      if (config.name.length &&
        config.description_for_certificate.length) {
        setIsLoading(true)
        try {
          const id = uuidv4()
          await addDoc(collection(dbFireStore, "reviews"), {
            ...config,
            answers: 0,
            form: forms,
            isValid: true,
            id: id,
          });
          setIsLoading(false)
          handlerOpening(false)
          toast.success("Tarefa criada com sucesso.", { duration: 150 })
          copyToClipboard(id)
        }
        catch (error) {
          setIsLoading(false)
          console.error(error)
          handleFirebaseRequestError(error as any);
        }
      } else {
        toast.warning("Campos Nome e Descrição de certificado são obrigatórios.")
      }
    } else {
      toast.warning(message)
    }
  }

  return (
    <Dialog onOpenChange={handlerOpening} open={isOpen}>
      <DialogTrigger asChild>
        <Button className='gap-2'>
          <TicketCheck size={20} />
          Registrar Feedback
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Registrar feedback</DialogTitle>
          <DialogDescription>
            Crie uma lista de feedback para um treinamento.
          </DialogDescription>
        </DialogHeader>
        <form id="createReviewForm" onSubmit={onSubmit} className="max-h-[50vh] overflow-y-auto">
          <div className="flex flex-col gap-4 py-4 px-1">
            <div className="flex flex-col gap-2">
              <Label>
                Nome*
              </Label>
              <Input
                className="mt-2"
                onChange={(e) => onChangeValue(e, 'name')}
                placeholder='Preencha o nome do treinamento'
                value={config.name} />
            </div>
            <div className="flex flex-col gap-2">
              <Label>
                Descrição dos certificados*
              </Label>
              <Input
                className="mt-2"
                placeholder='Preencha a descrição dos certificados'
                onChange={(e) => onChangeValue(e, 'description_for_certificate')}
                value={config.description_for_certificate} />
            </div>
            <div className="w-full h-[0.5px] my-2 bg-gray-200"></div>
            <div>
              <Button onClick={onCreateField} className="w-full gap-2 mt-4"><Plus size={20} /> Adicionar campo</Button>
              {
                forms.length > 0 &&
                <div className="flex flex-col gap-4 mt-4">
                  {
                    forms.map((form, idx) => (
                      <Card className="p-4" key={form.label + idx}>
                        <div className="flex gap-4">
                          <div className="flex flex-col flex-grow">
                            <Input
                              defaultValue={form.label}
                              placeholder="Digite o nome da questão"
                              onChange={(e) => handlerLabelField(e, idx)} />
                            {form.type === 'multiple' && (
                              <div className="mt-2">
                                {form.values.map((value, optionIdx) => (
                                  <div key={optionIdx} className="flex items-center gap-2 mt-2">
                                    <Input
                                      placeholder="Digite uma opção"
                                      value={value}
                                      onChange={(e) => handleOptionChange(idx, optionIdx, e.target.value)}
                                    />
                                    <Button
                                      variant="outline"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDeleteOption(idx, optionIdx);
                                      }}
                                      className="ml-2">
                                      <Trash size={16} />
                                    </Button>
                                  </div>
                                ))}
                                <Button
                                  variant="outline"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    addOption(idx);
                                  }}
                                  className="mt-2">
                                  <Plus size={16} /> Adicionar opção
                                </Button>
                              </div>
                            )}
                          </div>
                          <Select
                            defaultValue={form.type}
                            onValueChange={(val) => handlerOptionField(val as 'text' | 'multiple', idx)}>
                            <SelectTrigger className="w-[230px]">
                              <SelectValue placeholder="Tipo de campo" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="text">Texto</SelectItem>
                              <SelectItem value="multiple">Múltipla escolha</SelectItem>
                              <SelectItem value="one_to_ten">Escolha de um a dez</SelectItem>
                            </SelectContent>
                          </Select>
                          <Button
                            variant="outline"
                            onClick={() => handleDeleteField(idx)}>
                            <Trash size={16} />
                          </Button>
                        </div>
                      </Card>
                    ))
                  }
                </div>
              }
            </div>
          </div>
        </form>
        <DialogFooter>
          <Button form="createReviewForm" disabled={isLoading}>
            {
              isLoading && <LoaderCircle className='animate-spin mr-2' />
            }
            Registrar feedback</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
