import { FC, useEffect, useState } from 'react';
import { Eye } from 'lucide-react';
import { Download } from 'lucide-react';

import * as Card from "../ui/card";
import * as TableRadix from "../Table";
import { ActionsDropdown } from "../ActionsDropdown";
import { DropdownMenuItem } from "../ui/dropdown-menu";
import { TooltipMessage } from '../TooltipMessage';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { doc, onSnapshot } from 'firebase/firestore';
import { dbFireStore } from 'src/config/firebase';
import { format } from 'date-fns';
import { CertificatePDF } from '../CertificatePDF';

function truncateString(input: string, limit: number) {
  if (input.length > limit) {
    return input.slice(0, limit) + '...';
  }
  return input;
}

interface ICertificate {
  id: string;
  name: string;
  createdAt: string;
  description: string;
  values: { value: string; position: string }[]
}

interface IUserSession {

}

export const UserSession: FC<IUserSession> = () => {
  const { userLogged } = useAuthentication();
  const [certificates, setCertificates] = useState<ICertificate[]>([])
  const [name, setName] = useState<string | null>(null)


  useEffect(() => {
    if (userLogged?.uid) {
      const unsub = onSnapshot(
        doc(dbFireStore, "usersInfo", userLogged.uid),
        (doc) => {
          setName(doc.exists() ? doc.data().name : [])
          setCertificates(() => doc.exists() ? (doc.data().reviews as ICertificate[]) : [])
        },
      );
      return () => unsub();
    }
  }, [userLogged])
  return <div className="h-full min-h-[calc(100vh-64px)] flex gap-4 p-4">
    {
      certificates && name ? <Card.Card className="w-full">
        <Card.CardHeader>
          <Card.CardTitle>Certificados</Card.CardTitle>
          <Card.CardDescription>Confira a listagem dos certificados disponibilizados pela MK.</Card.CardDescription>
        </Card.CardHeader>
        <Card.CardContent>
          {
            certificates.length ? <TableRadix.Table>
              <TableRadix.TableHeader>
                <TableRadix.TableRow>
                  <TableRadix.TableHead>Título</TableRadix.TableHead>
                  <TableRadix.TableHead>Data de Emissão</TableRadix.TableHead>
                  <TableRadix.TableHead className="text-right">Ações</TableRadix.TableHead>
                </TableRadix.TableRow>
              </TableRadix.TableHeader>
              <TableRadix.TableBody>
                {certificates.map((certificate) => (
                  <TableRadix.TableRow key={certificate.id}>
                    <TableRadix.TableCell>
                      <TooltipMessage message={certificate.name}>
                        {truncateString(certificate.name, 24)}
                      </TooltipMessage>
                    </TableRadix.TableCell>
                    <TableRadix.TableCell>
                      <TooltipMessage message={format(certificate.createdAt, 'MM/dd/yyyy')}>
                        {truncateString(format(certificate.createdAt, 'dd/MM/yyyy'), 24)}
                      </TooltipMessage>
                    </TableRadix.TableCell>
                    <TableRadix.TableCell className="flex justify-end">
                      <ActionsDropdown>
                        <CertificatePDF description={certificate.description} name={name}>
                          <DropdownMenuItem className="gap-2 !text-black cursor-pointer">
                            <Download size={20} />
                            Fazer download
                          </DropdownMenuItem>
                        </CertificatePDF>
                      </ActionsDropdown>
                    </TableRadix.TableCell>
                  </TableRadix.TableRow>
                ))}
              </TableRadix.TableBody>
            </TableRadix.Table>
              :
              <div className='w-full my-12 text-gray-300 flex justify-center'>
                Você ainda não possui um certificado.
              </div>
          }
        </Card.CardContent>
      </Card.Card>
        :
        <div>
          carregando...
        </div>
    }
  </div>
}