import { toast } from "sonner";
import React, { ReactNode, useEffect, useState } from "react";
import {
  User,
  signOut,
  deleteUser,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";

import { IAuthenticationContext, IUserInfo } from "./types";
import { auth, dbFireStore, provider } from "../../config/firebase";
import { deleteDoc, doc, onSnapshot, setDoc } from "firebase/firestore";
import JSConfetti from "js-confetti";

interface AuthenticationProviderProps {
  children: ReactNode;
}

const jsConfetti = new JSConfetti()

export const AuthenticationContext = React.createContext<IAuthenticationContext>(
  {} as IAuthenticationContext,
);

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = React.useState<IUserInfo | null | undefined>(
    null
  );
  const [userLogged, setUserLogged] = React.useState<User | undefined>(
    undefined,
  );

  const onSignOut = async () => {
    await signOut(auth);
  };

  const onSignInWithGoogle = async () => {
    await signInWithPopup(auth, provider);
    window.location.href = "/dashboard";
  };

  const deleteAccount = async () => {
    if (userLogged) {
      try {
        await deleteUser(userLogged);
        toast.success("Conta deletada com sucesso.");
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (userLogged) {
      const unsub = onSnapshot(
        doc(dbFireStore, "usersInfo", userLogged.uid),
        (doc) => {
          setUserInfo(() => doc.exists() ? doc.data() as IUserInfo : undefined)
        },
      );
      return () => unsub();
    }
    return undefined
  }, [userLogged])

  const onClearWinner = async() => {
    await setDoc(doc(dbFireStore, "userEvents", "draw"), {
      winner: null,
      winnerMessage: null,
    });
  }

  useEffect(() => {
      const unsub = onSnapshot(
        doc(dbFireStore, "userEvents", "draw"),
       async (doc) => {
          if (doc.exists() && doc.data().winner === userLogged?.email) {
             jsConfetti.addConfetti({
                confettiColors: [
                  '#ff0a54', '#ff477e', '#ff7096', '#ff85a1', '#fbb1bd', '#f9bec7',
                ],
              })
              toast.success(doc.data().winnerMessage);
              await onClearWinner()
          }
        },
      );
      return () => unsub();
  }, [userLogged])

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      if (user) {
        setUserLogged(user);
      } else {
        setUserLogged(undefined);
      }
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);
  return (
    <AuthenticationContext.Provider
      value={{
        userInfo,
        userLogged,

        onSignOut,
        deleteAccount,
        onSignInWithGoogle,
      }}
    >
      {!loading && children}
    </AuthenticationContext.Provider>
  );
};