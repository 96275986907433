import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Draw } from "./pages/Private/Draw";
import Layout from "./pages/Private/Layout";
import { Toaster } from './components/ui/toaster';
import { LandingPage } from "./pages/public/LandingPage";
import { ProtectedPage } from "./components/ProtectedPage";
import { AnswerReview } from "./pages/Private/AnswerReview";
import { ROUTES_PAGES } from "./pages/Private/private-routers";
import { AuthenticationProvider } from "./providers/Authentication";

function App() {
  const pages = createBrowserRouter([
    {
      path: '/',
      element: <LandingPage />,
    },
    {
      path: '/review/:reviewID',
      element: <ProtectedPage element={AnswerReview} validatePage={true} />,
    },
    {
      path: '/draw/:drawID',
      element: <ProtectedPage element={Draw} validatePage={true} />,
    },
    {
      path: '/dashboard',
      children: ROUTES_PAGES,
      element: <ProtectedPage element={Layout} validatePage={true} />,
    }
  ])


  return (
    <AuthenticationProvider>
      <RouterProvider router={pages} />
      <Toaster />
    </AuthenticationProvider>
  )
}

export default App;
