import { CheckedState } from "@radix-ui/react-checkbox";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { LoaderCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { RulesOfDrawModal } from "src/components/RulesOfDrawModal";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Checkbox } from "src/components/ui/checkbox";
import { Label } from "src/components/ui/label";
import { dbFireStore } from "src/config/firebase";
import { useAuthentication } from "src/hooks/useAuthentication";

interface DrawProps {

}

interface IDraw {
  id: string;
  name: string;
  limit: number;
  winner: string | null;
  participants: string[];
}

export function Draw({ }: DrawProps) {
  const navigate = useNavigate();
  const { drawID } = useParams();
  const { userLogged } = useAuthentication();
  const [draw, setDraw] = useState<IDraw | null>(null);
  const [documentID, setDocumentID] = useState<string | null>(null);
  const [isAcceptedTerms,setIsAcceptedTerms] = useState<CheckedState>(false);

  if (!drawID) {
    toast.info("Sorteio inválido.");
    navigate("/");
  }

  const fetchDrawData = async () => {
    const q = query(collection(dbFireStore, "draws"), where("id", "==", drawID));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length < 1) {
      toast.info("Sorteio não encontrado.");
      return navigate("/");
    }
    const data = querySnapshot.docs[0].data() as IDraw;
    if ((data.participants.length + 1) > data.limit) {
      toast.info("Vaga esgotadas.");
      return navigate("/");
    }

    if (data.participants.findIndex((e) => e === userLogged?.email) > -1) {
      toast.info("você já está participando deste sorteio.");
      return navigate("/");
    }

    setDocumentID(querySnapshot.docs[0].id)
    setDraw(data);
  };

  const onSignInDraw = async () => {
    if (documentID && draw) {
      try {
        await setDoc(doc(dbFireStore, "draws", documentID), {
          ...draw,
          participants: [...draw.participants, userLogged?.email]
        });
        toast.success("Parabéns, você entrou neste sorteio.")
        navigate("/dashboard")
      } catch (error) {
        console.error(error as any);
      }
    }
  }

  useEffect(() => {
    if (drawID) {
      fetchDrawData();
    }
  }, [drawID]);
  return (<div className="min-h-screen flex items-center justify-center">

    {draw ?
      <Card className="p-4 min-w-96 flex flex-col items-center">
        <Label className="text-lg font-bold">
          Participar do sorteio: {draw?.name}
        </Label>
        <div className="flex items-center gap-2 mt-4">
          <Checkbox id="terms-and-conditions-draw" onCheckedChange={(value)=>setIsAcceptedTerms(value)} />
          <label className="text-xs" htmlFor="terms-and-conditions-draw">Para participar do sorteio, você confirma que concorda com as <RulesOfDrawModal /> estabelecidas.</label>
        </div>
        <Button disabled={!isAcceptedTerms} className="w-full mt-4" onClick={onSignInDraw}>
          Participar do sorteio
        </Button>
      </Card>
      :
      <LoaderCircle className='animate-spin mr-2' />
    }

  </div>);
}