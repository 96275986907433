import html2canvas from "html2canvas"
import Background from "../../assets/fundo.png"
import Logo from "../../assets/logo.png"
import Astronauta from "../../assets/astronauta.png"
import { ReactNode, useEffect, useRef, useState } from "react"

interface IPDGPage {
  name: string;
  description: string;
}

interface CertificatePDFProps extends IPDGPage {
  children: ReactNode;
}

interface CssProperty {
  [x: string]: React.CSSProperties
}

export const styles: CssProperty = {
  page: {
    width: "920px",
    height: "595px",
    background: `url(${Background})`,
    fontFamily: "Baloo 2",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    padding: "30px",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  title: {
    fontFamily: "Baloo 2",
    fontSize: 59,
    fontWeight: 700,
    color: "#205266",
    textShadow: '2px 2px 4px #072f4f'
  },
  label: {
    fontSize: "26px",
    fontWeight: 700,
    color: "#205266"
  },
  value: {
    fontSize: "26px",
    fontWeight: 600,
    color: "gray"
  },
  bottomSide: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  logo: {
    height: 40,
    width: "auto",
    marginTop: "14px",
  },

  flexRow: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "space-between"
  },

  astro: {
    width: "auto",
    height: "420px"
  },

  items: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }
}

interface IPDGPage {
  name: string;
  description: string;
}

const PDFPage = ({ name, description }: IPDGPage) => {

  return (<div style={styles.page}>
    <div style={styles.flexRow}>
      <img src={Astronauta} style={styles.astro} />
      <div style={styles.items}>
        <h1 style={styles.title}>
          Certificado
        </h1>
        <p style={{ ...styles.label, marginBottom: 70 }}>
          de participação
        </p>
        <p style={{ ...styles.label }}>
          Certificamos que
        </p>
        <p style={{ ...styles.value }}>
          {name}
        </p>
        <p style={{ ...styles.label }}>
          Participou e concluiu o treinamento do assunto:
        </p>
        <p style={{ ...styles.value }}>
          {`“${description}”`}
        </p>
        <div style={{ ...styles.bottomSide }}>
          <p style={{ ...styles.label }}>
            Realizado por
          </p>
          <img src={Logo} style={styles.logo} />
        </div>
      </div>
    </div>
  </div>)
}

export function CertificatePDF({ name, description, children }: CertificatePDFProps) {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const jpegRef = useRef<HTMLDivElement>(null);

  function handleDownloadJPEG() {
    setIsOpened(() => true)
  }

  useEffect(() => {
    if (isOpened) {
      if (!jpegRef.current) return;

      html2canvas(jpegRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.download = "certificado.jpg";
        link.href = canvas.toDataURL("image/jpeg");
        link.click();
        setIsOpened(false)
      });
    }
  }, [isOpened])
  return (
    <div>
      {
        isOpened ?
          <div className="absolute top-0 left-[-999px] -z-50" ref={jpegRef}>
            <PDFPage description={description} name={name} />
          </div>
          :
          <></>
      }

      <div className="w-fit h-fit" onClick={handleDownloadJPEG}>
        {children}
      </div>
    </div>
  );
}