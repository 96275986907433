import { z } from "zod";
import { toast } from "sonner";
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoaderCircle } from 'lucide-react';
import { zodResolver } from "@hookform/resolvers/zod";


import * as Card from '../ui/card';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { doc, setDoc } from 'firebase/firestore';
import { dbFireStore } from '../../config/firebase';
import { useAuthentication } from '../../hooks/useAuthentication';
import { handleFirebaseRequestError } from "src/utils/handlerErrors";

interface IUpdateInformations {
}

const formSchema = z.object({
  name: z.string().min(1, { message: "Campo obrigatória" }),
  provider: z.string().min(1, { message: "Campo obrigatória" }),
})


export const UpdateInformations: FC<IUpdateInformations> = () => {
  const { userLogged } = useAuthentication()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    if (userLogged) {
      setIsLoading(true)
      try {
        await setDoc(doc(dbFireStore, "usersInfo", userLogged.uid), {
          ...values,
          email: userLogged.email,
          reviews: [],
        });
        toast.success("Informações atualizadas com sucesso.");
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        handleFirebaseRequestError(error as any);
      }
    }
  }

  return (
    <div className="min-h-[calc(100vh-64px)] flex items-center justify-center">
      <Card.Card className="w-[560px]">
        <Card.CardHeader>
          <Card.CardTitle>Atualizar dados</Card.CardTitle>
          <Card.CardDescription>
            Por favor, forneça seus dados para que possamos emitir os certificados futuramente.
          </Card.CardDescription>
        </Card.CardHeader>
        <Card.CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-4">
            <div>
              <Label>
                Nome*
              </Label>
              <Input
                className="mt-2"
                {...register("name")}
                placeholder='Preencha seu nome' />
              {errors.name?.message && <Label className='text-xs font-extralight text-red-600'> {errors.name?.message} </Label>}
            </div>
            <div>
              <Label>
                Nome da Empresa*
              </Label>
              <Input
                className="mt-2"
                {...register("provider")}
                placeholder='Preencha o nome da empresa' />
              {errors.provider?.message && <Label className='text-xs font-extralight text-red-600'> {errors.provider?.message} </Label>}
            </div>
            <Button className='w-full mt-4' type="submit" disabled={isLoading}>
              {
                isLoading && <LoaderCircle className='animate-spin mr-2' />
              }
              Atualizar
            </Button>
          </form>
        </Card.CardContent>
      </Card.Card>
    </div>
  );
}