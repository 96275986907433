import { useState } from "react";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "../ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from "../ui/sheet";

import { Menu } from "lucide-react";
import { buttonVariants } from "../ui/button";

import Logo from "../../assets/mk-negativo.png";
import { Link, useNavigate } from "react-router-dom";
import { useAuthentication } from "../../hooks/useAuthentication";


export const NavBarLogged = () => {
  const navigate = useNavigate()
  const { onSignOut } = useAuthentication()
  const [isOpen, setIsOpen] = useState<boolean>(false);


  const goToHome = () => navigate("/dashboard")

  return (
    <header className="sticky top-0 z-1 w-full h-16 bg-[#27272a] dark:bg-background">
      <NavigationMenu className="mx-auto h-full">
        <NavigationMenuList className="container h-14 px-4 w-screen flex justify-between ">
          <div className="flex items-center gap-8">
            <NavigationMenuItem className="cursor-pointer" onClick={goToHome}>
              <img src={Logo} alt="MK solutions" className="h-10" />
            </NavigationMenuItem>
            <div className="hidden md:flex gap-6">
            </div>
          </div>
          <span className="flex md:hidden">

            <Sheet
              open={isOpen}
              onOpenChange={setIsOpen}
            >
              <SheetTrigger className="px-2">
                <Menu
                  className="flex md:hidden h-5 w-5 text-white"
                  onClick={() => setIsOpen(true)}
                >
                </Menu>
              </SheetTrigger>

              <SheetContent side={"left"}>
                <SheetHeader>
                  <img src="https://www.mksolutions.com.br/wp-content/uploads/2022/11/mk-positive.svg" alt="MK solutions" className="h-10" />
                </SheetHeader>

                <nav className="flex flex-col justify-center items-center gap-2 mt-4">
                  <button
                    onClick={onSignOut}
                    className={`border ${buttonVariants({ variant: "ghost", className: "w-full hover:!text-[#f8fafc]" })}`}
                  >
                    Sair
                  </button>
                </nav>
              </SheetContent>
            </Sheet>
          </span>

          <nav className="hidden md:flex gap-2">
            <button
              onClick={onSignOut}
              className={` ${buttonVariants({
                variant: "ghost",
                className: "!text-[#f8fafc]"
              })}`}
            >
              Sair
            </button>
          </nav>
        </NavigationMenuList>
      </NavigationMenu>
    </header>
  );
};