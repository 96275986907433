import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { Button } from "src/components/ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { dbFireStore } from "src/config/firebase";
import { useAuthentication } from "src/hooks/useAuthentication";

interface IQuestion {
  id: number;
  label: string;
  isValid: boolean;
  values: string[];
  type: 'text' | 'multiple' | 'one_to_ten';
}

interface IDraw {
  id: string;
  answers: 0;
  name: string;
  isValid: boolean;
  form: IQuestion[];
  description_for_certificate: string;
}

interface Answers {
  value: string;
  position: number;
}

export function AnswerReview() {
  const navigate = useNavigate();
  const { userLogged } = useAuthentication();
  const { reviewID } = useParams();
  const [review, setReview] = useState<IDraw | null>(null);
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [documentID, setDocumentID] = useState<string | null>(null);

  if (!reviewID) {
    toast.info("Feedback não encontrado.");
    navigate("/");
  }

  const fetchReviewData = async () => {
    const q = query(collection(dbFireStore, "reviews"), where("id", "==", reviewID));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length < 1) {
      toast.info("Feedback não encontrado.");
      return navigate("/");
    }
    const data = querySnapshot.docs[0].data() as IDraw;
    if (!data.isValid) {
      toast.info("Poxa, feedback finalizado.");
      return navigate("/");
    }
    setDocumentID(querySnapshot.docs[0].id)
    setReview(data);
  };

  const onAddReviewToUser = async (answers: Answers[]) => {
    const q = query(collection(dbFireStore, 'usersInfo'));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.find((val) => val.id === userLogged?.uid)
    const values = data?.data()

    if (values && data) {
      try {
        await setDoc(doc(dbFireStore, "usersInfo", data.id), {
          ...values,
          reviews: [...values.reviews, {
            id: reviewID,
            values: answers,
            name: review?.name,
            createdAt: Date.now(),
            description: review?.description_for_certificate,
          }]
        });
      } catch (error) {
        console.error(error as any);
      }
    }
  }

  useEffect(() => {
    if (reviewID) {
      fetchReviewData();
    }
  }, [reviewID]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const allQuestionsAnswered = review?.form.every((question, idx) => {
      if (question.type === 'text') {
        return true;
      }
      return answers[idx]?.value !== undefined;
    });

    if (review && allQuestionsAnswered && documentID) {
      try {
        await onAddReviewToUser(answers)
        await setDoc(doc(dbFireStore, "reviews", documentID), {
          ...review,
          answers: review.answers + 1,
        });
        toast.success("Feedback respondido com sucesso.");
        navigate("/dashboard");
      } catch (error) {
        console.log(error)
        toast.error("Erro ao enviar respostas, por favor tente mais tarde.")
      }
    } else {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
    }
  };

  const setValue = (value: string, idx: number) => {
    const _answers = answers;

    _answers[idx] = {
      value,
      position: Number(idx)
    }

    setAnswers([..._answers]); // Atualiza corretamente o estado
  }

  const handleButtonClick = (value: string, idx: number) => {
    setValue(value, idx);
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <Card className="w-[620px]">
        <CardHeader>
          <CardTitle>Sua opinião importa!</CardTitle>
          <CardDescription>Por favor, compartilhe sua opinião preenchendo o formulário abaixo.</CardDescription>
        </CardHeader>
        <CardContent>
          <form
            id="registerReviewUserForm"
            onSubmit={handleSubmit}
            className="w-full flex flex-col items-center gap-4"
          >
            {review
              ? review.form.map((question, idx) => (
                <div key={question.id + question.label} className="flex flex-col gap-2 w-full">
                  <Label>{question.label} {question.type !== 'text' ? <strong className="text-red-600">*</strong> : <></>}</Label>
                  {question.type === "text" ? (
                    <Input
                      placeholder="Digite aqui"
                      name={`question-${question.id}`}
                      onChange={(e) => setValue(e.target.value, idx)}
                    />
                  ) : question.type === "one_to_ten" ? (
                    // Implementação dos botões horizontais para one_to_ten
                    <div className="flex w-full items-center justify-between">
                      {[...Array(10)].map((_, i) => (
                        <Button
                          key={i}
                          variant={answers[idx]?.value === `${i + 1}` ? "destructive" : "outline"}
                          className={`w-10 h-10 ${answers[idx]?.value === `${i + 1}` ? "bg-blue-500 text-white" : ""}`}
                          onClick={() => handleButtonClick(`${i + 1}`, idx)}
                          type="button"
                        >
                          {i + 1}
                        </Button>
                      ))}
                    </div>
                  ) : (
                    // Implementação do RadioGroup para o tipo "multiple"
                    <RadioGroup
                      required
                      defaultValue=""
                      className="flex flex-col gap-2"
                      name={`question-${question.id}`}
                      onValueChange={(e) => setValue(e, idx)}
                    >
                      {question.values.map((value, index) => (
                        <div key={value + index} className="flex items-center">
                          <RadioGroupItem value={value} />
                          <Label className="ml-2">{value}</Label>
                        </div>
                      ))}
                    </RadioGroup>
                  )}
                </div>
              ))
              : null}
          </form>
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button form="registerReviewUserForm" className="w-full bg-[#63d391]" type="submit">
            Enviar
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
