import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../../config/firebase";


export type IProtectedPageProps = {
  redirectTo?: string;
  validatePage?: boolean;
  elementProps?: Record<string, any>;
  element: React.ComponentType<any>;
};

export const ProtectedPage: React.FC<IProtectedPageProps> = ({
  elementProps,
  element: Element,
  validatePage = true,
  redirectTo = "/",
}) => {
  const [user] = useAuthState(auth);

  if (validatePage) {
    if (!user) {
      return <Navigate to={redirectTo} />;
    }
  }

  if (Element) {
    return <Element {...(elementProps || {})} />;
  }

  return <Outlet />;
};