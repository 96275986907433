import { FC } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";

interface IRulesOfDrawModal {

}

export const RulesOfDrawModal: FC<IRulesOfDrawModal> = () => {
  return (
    <Dialog>
      <DialogTrigger className="font-bold underline">
        regras
      </DialogTrigger>
      <DialogContent className="w-full max-w-[600px] h-full max-h-[680px] overflow-auto">
        <DialogHeader>
          <DialogTitle>Regras para participar do sorteio</DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          <label className="font-bold">
            Sobre o Prêmio:
          </label>
          <ul className="list-none mb-4">
            <li>
              Um dia de Treinamento In Company na modalidade online, com duração de 8 horas, no valor de R$1.024,00.
            </li>
          </ul>

          <label className="font-bold">
            Escolha do Tema:
          </label>
          <ul className="list-none mb-4">
            <li>
              O ganhador poderá escolher o tema do treinamento. É importante informar o assunto no momento do agendamento, para que possamos organizar a agenda do especialista.
            </li>
          </ul>

          <label className="font-bold">
            Participantes:
          </label>
          <ul className="list-none mb-4">
            <li>
              Não há limite de participantes do lado do cliente durante o treinamento.
            </li>
          </ul>

          <label className="font-bold">
            Agendamento:
          </label>
          <ul className="list-none mb-4">
            <li>
              Após o evento, o ganhador possui um prazo de 15 dias para realizar o agendamento do treinamento e 25 dias para realização do treinamento. Caso o agendamento não seja feito dentro desse período, o prêmio será perdido.
            </li>
          </ul>

          <label className="font-bold">
            Condições para Agendamento:
          </label>
          <ul className="list-none mb-4">
            <li>
              O treinamento será realizado de segunda a sexta-feira, das 08:00 às 18:00 (horário de Brasília). O horário exato de início e término será acordado com o ganhador.
              O treinamento ocorrerá apenas em dias úteis, não sendo possível agendar em feriados.
              O reagendamento da data definida não será permitido, devido à organização da agenda do especialista.
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  )
}